// login ...
export const loginURL = "/auth/login";
export const logoutURL = "/auth/logout";
export const currentCustomerURL = "/current-customer";
// login ...

// products ...
export const productReviewURL = "/product/review";
export const getProductReviewURL = (product_id) =>
  `/product/review/${product_id}`;
export const instockNotifyURL = "/product/instocknotify";
export const productListingURL = "/product-listing";
export const featureProductURL = productListingURL + "?type=featured";
export const newProductURL = productListingURL + "?type=new";
export const popularProductURL = productListingURL + "?type=popular";
export const productPriceURL = "/bc/product/prices";
// products ...

// search-spring ...
export const searchSpringSearchURL = "/product-search";
export const searchSpringSuggestURL = "/product-suggest";
export const searchSpringAutoCompleteURL = "/product-autocomplete";
// search-spring ...

// customer ...
export const forgotPasswordURL = "/customer/forgotpassword";
export const resetPasswordURL = "/customer/resetpassword";
export const customerNewAccountURL = "/customer/newaccount";
export const customerAddcustomerNewAccountURL = "/customer/addnewaccount";

export const customerLoyaltyrewardURL = "/customers/loyalty/rewards";
export const customerLoyaltyCouponsURL = "/customers/loyalty/customer/coupons";
export const loyaltyCouponURL = "/customers/loyalty/coupon";
export const customerLoyaltyHistoryURL = "/customers/loyalty/customer/history";
// customer ...

// admin-user ...
export const adminUserLoginURL = "/auth/customer/login";
// admin-user ...

// bigcommerce ...
export const bcApiURL = "/bc/api";
// bigcommerce ...

// account ...
export const myAccountURL = "/myaccount";
// account ...

// cart ...
export const cartURL = (cartId) => (cartId ? `/cart` : null);
export const cartItemsURL = (cartId) =>
  cartId ? `/cart/${cartId}/items` : null;
export const cartCouponURL = (cartId) =>
  cartId ? `/cart/${cartId}/coupons` : null;
export const checkoutURL = (cartId) =>
  cartId ? `/cart/${cartId}/checkout` : null;
export const cartSummaryURL = (cartId) => (cartId ? `/cart/summary` : null);
export const cartFormItemsURL = "/cart-form/items";
export const saveCartURL = (cartId) => (cartId ? `/cart/save/${cartId}` : null);
export const saveCartRequestURL = "/save-cart";

export const reorderURL = "/reorder";
// cart ...

// static resources ...
export const staticImg = `/static/images`;
// static resources ...

// banner static image ....
export const bannerStaticImg = `/banner/`;

export const globalStaticImg = `/appbuilder/`;

// blogs static image .....
export const blogStaticImg = `/blog`;

//orders
export const orders = "/orders";
export const bulkOrders = "/bulk/orders/product/variant/info";
export const createPo = "purchase/orders";
export const brandList = "/bulkOrders/product/brandlist";

export const getorderProductsUrl = (order_id) =>
  order_id ? `/order-detail/${order_id}/products` : null;
export const getorderShippingUrl = (order_id) =>
  order_id ? `/order-detail/${order_id}/shipping` : null;

// others ...
export const orderProductsURL = "/orderid/products";
export const customerOrdersURL = "/customer/orders";
// others ...

// store info ..
export const storeInfoURL = "/storeinfo";
//brand
export const brandsStaticURL = "/brands";

// sample csv download
export const sampleCsvURL = "/scanner/csvdata";

// representatives details
export const customerPocURL = "/customer-poc";
